<script>
  import { writable, derived } from 'svelte/store';
  import { getContext } from "svelte";
  import { rating_types } from './rating_types.js';
  import RatingButton from "./RatingButton.svelte";
  export let asset_id;
  export let element;
</script>

<div class="rating">
  {#each $rating_types as rating_type}
    <RatingButton {rating_type} {asset_id} {element} />
  {/each}
</div>

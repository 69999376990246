import { writable } from "svelte/store";
import { setContext, onMount } from "svelte";

export const rating_types = writable([]);

async function fetch_rating_types() {
    const response = fetch(
        "/api/v1/enums/rating-types/?show_in_gallery=True&is_user_editable=True"
    )
        .then((response) => response.json())
        .then((data) => {
            rating_types.set(data.results);
        })
        .catch((error) => {
            console.log(error);
            return [];
        });
}
fetch_rating_types();

<script>
  export let rating_type;
  export let asset_id;
  export let element;
  let score;
  let showThisParameter;
  $: init(element);
  import { csrfHeader } from "../lib";

  async function init(element) {
    if (element) {
      await set_params(element);
    }
  }

  async function set_params(element) {
    if(element.attributes["data-" + rating_type['slug']]){
       showThisParameter = true;
       const score_val = element.attributes["data-" + rating_type['slug']].value;
       if (rating_type.controlType == 'boolean'){
          score = score_val == 1 ? true : false;
       } else {
          score = score_val;
       };
    }
  }

  function hideAllToasts() {
    // Query all elements with the .toast class and hide them
    const allToasts = document.querySelectorAll('.toast');
    allToasts.forEach(toastEl => {
      const toast = bootstrap.Toast.getInstance(toastEl);
      if (toast) {
        toast.hide();
      }
    });
  }

  async function change_rating() {
    hideAllToasts();
    const headers = csrfHeader();
    if (rating_type.rangeTo < score) {
       score = rating_type.rangeTo;
    }
    if (rating_type.rangeFrom > score) {
       score = rating_type.rangeFrom;
    }
    var score_val = score;
    if (rating_type.controlType == 'boolean') {
       score_val = (score ? 1  : 0);
    };
    const body = new URLSearchParams({ "score": score_val });
    const res = await fetch(`/api/v1/assets/${asset_id}/rating/${rating_type.slug}/`, {
       method: 'PUT',
       body: body,
       headers
    }).then(data => {
      var selector = `.toast-${rating_type.slug}-${score ? 'thank_you' : 'cancelled'}`;
      var toastElList = [].slice.call(document.querySelectorAll(selector));
      var toastList = toastElList.map(function (toastEl) {
        return new bootstrap.Toast(toastEl, {})
      })
      toastList[0].show()
    });
  }
</script>

<div class="rating__button">
   {#if showThisParameter }
      {#if rating_type.controlType == 'boolean' }
         <span class="like-checkbox" title="{rating_type.tooltip}" data-bs-html="true" data-bs-toggle="tooltip" data-bs-placement="top">
           <input type="checkbox" name="{rating_type.slug}" class="like-checkbox-element" id="id_{rating_type.slug}" bind:checked="{score}" on:change="{change_rating}"/>
           {#if score }
               {@html rating_type.symbolChecked}
           {:else }
               {@html rating_type.symbolUnchecked}
           {/if}
         </span>
      {:else if rating_type.controlType == 'integer' }
         <input type="number" name="{rating_type.slug}" class="form-control-sm" id="id_{rating_type.slug}" bind:value="{score}" on:input="{change_rating}" min="{rating_type.rangeFrom}" max="{rating_type.rangeTo}">
      {:else }
         Unknown control type
      {/if}
   {/if}
</div>

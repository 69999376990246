import Rating from "./Rating.svelte";

let ratingInstances = [];

function load_rating_on_element(asset_card) {
    if (asset_card.querySelector(".rating_button")) {
        const element = asset_card.querySelector(".rating_button");
        if (element) {
            const rating = new Rating({
                target: element,
                props: {
                    asset_id: element.getAttribute("data-id"),
                    element: element,
                },
            });
            ratingInstances.push(rating);
        }
    }
}

// Initial load for existing .bk-card elements
document.querySelectorAll(".bk-card").forEach(load_rating_on_element);

// Observe for changes in #main-area and also handle infinite-load on .asset-container
const observer = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
        if (mutation.addedNodes && mutation.addedNodes.length > 0) {
            mutation.addedNodes.forEach((node) => {
                if (node.nodeType === 1) {
                    if (node.matches(".bk-card")) {
                        load_rating_on_element(node);
                    } else {
                        const bkCards = node.querySelectorAll(".bk-card");
                        bkCards.forEach(load_rating_on_element);
                    }
                }
            });
        }
    });
});

var main_area = document.querySelector("#main-area");
if (main_area !== null) {
    observer.observe(main_area, {
        childList: true,
        subtree: true,
    });
}
